import { trackRiskLoginModalLoginWithSsoClick } from "@faire/web-api/events/risk/click/loginModalLoginWithSso";
import { ILoginResponse } from "@faire/web-api/indigofair/data/ILoginResponse";
import { ISignUpSuggestions } from "@faire/web-api/indigofair/data/ISignUpSuggestions";
import { ISSOToken } from "@faire/web-api/indigofair/data/ISSOToken";
import { SocialSignOnProvider } from "@faire/web-api/indigofair/data/SocialSignOnProvider";
import { StrictLocalizeFunction } from "@faire/web/common/localization";
import { makeObservable } from "@faire/web/common/makeObservable";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import { action, computed, observable } from "mobx";

import { defaultSSOErrorMessage } from "@faire/retailer/components/SocialSignOnButtons/errorMessages";
import { ssoCache } from "@faire/retailer/features/SignUp/SSORequestData";

/**
 * @deprecated prefer to use `useSSOStore` or `withStores` instead
 */
export class SSOStore {
  constructor() {
    makeObservable(this);
  }

  /**
   * @trackfunction
   */
  static get = singletonGetter(SSOStore);

  readonly BLANK_SIGN_UP_SUGGESTIONS = ISignUpSuggestions.build({
    email_address: "",
    first_name: "",
    last_name: "",
    profile_image_url: "",
    provider: SocialSignOnProvider.SOCIAL_SIGN_ON_PROVIDER_UNKNOWN,
  });

  /** Used to populate the sign up fields for the user. */
  @observable
  signUpSuggestions: ISignUpSuggestions =
    ssoCache.get()?.signUpSuggestions ?? this.BLANK_SIGN_UP_SUGGESTIONS;

  /** This is used to convey to the Sign Up flow or the Sign In Views
  that SSO is being used in lieu of username password */
  @observable
  isActive: boolean = ssoCache.get()?.isActive ?? false;

  /** Used to indicate that it is safe to show script load error messages from the SSO button */
  @observable
  userClickedSSOButton: boolean = false;

  /** Token (JWT) received from the Social Provider to identify and authenticate the user. Is decoded to get email and maybe other additional information like name. */
  @observable
  private id_token: string = ssoCache.get()?.id_token ?? "";

  /** The Provider currently being used by the user eg. Google or Apple */
  @observable
  provider: SocialSignOnProvider =
    (ssoCache.get()?.signUpSuggestions?.provider as
      | SocialSignOnProvider
      | undefined) ?? SocialSignOnProvider.SOCIAL_SIGN_ON_PROVIDER_UNKNOWN;

  /** All possible Providers the user has registered to authenticate themselves with */
  @observable
  providers: SocialSignOnProvider[] = [];

  /** Any SSO related error message. To be displayed by whatever Sign Up/In modal the user has opened. */
  @observable
  errorMessage: string = "";

  /** Used to convey information to an MFA View */
  @observable
  mfaDescription: string = "";

  /** Used to convey mfa title information to an MFA View */
  @observable
  mfaTitle: string = "";

  /** Used to convey mfa options information to an Select MFA method View */
  @observable
  mfaOptions: ILoginResponse.IMfaOption[] = [];

  /** If user enters an email which is tied to a social login, this will
  trigger the SignInController to prompt the user to sign in with SSO */
  @observable
  promptSSO: boolean = false;

  /** If the user also has password, this allows user to route from the SSOView to EnterPasswordView */
  @observable
  userHasPassword: boolean = false;

  /** Set the SignInController to activate View.CONFIRM_PASSWORD on mount */
  @observable
  showConfirmPasswordView: boolean = false;

  @observable
  private strictLocalize: StrictLocalizeFunction | undefined;

  setStrictLocalize = (strictLocalize: StrictLocalizeFunction) => {
    this.strictLocalize = strictLocalize;
  };

  @action
  reset = () => {
    this.signUpSuggestions = this.BLANK_SIGN_UP_SUGGESTIONS;
    this.id_token = "";
    this.isActive = false;

    ssoCache.clear();
  };

  @action
  set = (
    signUpSuggestions: ISignUpSuggestions | undefined,
    id_token: string,
    provider: SocialSignOnProvider
  ) => {
    if (signUpSuggestions) {
      this.signUpSuggestions = signUpSuggestions;
    }
    this.id_token = id_token;
    this.provider = provider;

    this.isActive = true;

    ssoCache.save({
      signUpSuggestions,
      id_token,
      provider,
      isActive: true,
    });
  };

  @computed
  get ssoToken(): ISSOToken {
    const { id_token, provider } = ssoCache.get() ?? {};

    return {
      id_token: this.id_token ?? id_token,
      provider:
        this.provider === "SOCIAL_SIGN_ON_PROVIDER_UNKNOWN"
          ? provider
          : this.provider,
    };
  }

  @action
  setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  @action
  setLocalizedErrorMessage = () => {
    if (this.strictLocalize) {
      this.errorMessage = defaultSSOErrorMessage(this.strictLocalize);
    }
  };

  @action
  resetErrorMessage = () => {
    this.errorMessage = "";
  };

  @action
  setMFADescription = (mfaDescription: string) => {
    this.mfaDescription = mfaDescription;
  };

  @action
  resetMFADescription = () => {
    this.mfaDescription = "";
  };

  @action
  setMFATitle = (mfaTitle: string) => {
    this.mfaTitle = mfaTitle;
  };

  @action
  resetMFATitle = () => {
    this.mfaTitle = "";
  };

  @action
  setMFAOptions = (mfaOptions: ILoginResponse.IMfaOption[]) => {
    this.mfaOptions = mfaOptions;
  };

  @action
  resetMFAOptions = () => {
    this.mfaOptions = [];
  };

  @action
  setProviders = (providers: SocialSignOnProvider[]) => {
    this.providers = providers;
  };

  @action
  resetProviders = () => {
    this.providers = [];
  };

  @action
  setPromptSSO = (promptSSO: boolean) => {
    this.promptSSO = promptSSO;
  };

  @action
  recordSSOButtonClick = () => {
    trackRiskLoginModalLoginWithSsoClick();
    this.userClickedSSOButton = true;
  };

  @action
  setUserHasPassword = (userHasPassword: boolean) => {
    this.userHasPassword = userHasPassword;
  };

  @computed
  get showSelectMFA(): boolean {
    return this.mfaOptions.length !== 0;
  }

  @action
  setShowConfirmPasswordView = (showConfirmPasswordView: boolean) => {
    this.showConfirmPasswordView = showConfirmPasswordView;
  };
}

export const useSSOStore = createStoreHook(SSOStore);
