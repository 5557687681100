import { singletonGetter } from "@faire/web/common/singletons/getter";
import { mockStrictLocalize } from "@faire/web/common/testing/unit/mockStrictLocalize";
import { MessengerStore } from "@faire/web/messenger/store/MessengerStore";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import { action, observable } from "mobx";

import { UserStore } from "./domain/UserStore";

/**
 * Factory methods to get access to messenger related classes.
 * @deprecated prefer to use `useMessengerFactory` or `withStores` instead
 */
export class MessengerFactory {
  static get = singletonGetter(MessengerFactory);

  @observable
  private _messengerStore: MessengerStore | undefined;

  // When TAB_RENAME_LOGGEDIN_230313 is treatment, we wish the message blink function to know the title dynamically, the `ChatEventListener` will use this value to get the latest title of our site.
  @observable
  tabTitle: string | undefined;

  set messengerStore(messengerStore: MessengerStore) {
    this._messengerStore = messengerStore;
  }

  @action
  setTabTitle = (tabTitle: string | undefined) => {
    this.tabTitle = tabTitle;
  };

  get messengerStore() {
    return (
      this._messengerStore ||
      new MessengerStore(UserStore.get().user, mockStrictLocalize)
    );
  }
}

export const useMessengerFactory = createStoreHook(MessengerFactory);
